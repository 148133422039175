import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
//import BannerAlt from '../components/blocks/BannerAlt'
import classes from '../components/blocks/Slider.module.css'
import CallToAction from '../components/blocks/CallToAction'
import Images from '../components/blocks/Images'
import Seo from '../components/Seo'
 
const Construction = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(limit: 1, filter: { wordpress_id: { eq: 9 } }) {
        edges {
          node {
            title
            status
            content
            wordpress_id
            featured_media {
              source_url
            }
            acf {
              banner_link
              banner_text
              call_to_action_content
              image_one {
                source_url
              }
              image_two {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const acfData =  data.allWordpressPage.edges[0].node.acf;
  return (
    <Layout
      featuredImg={
        data.allWordpressPage.edges[0].node.featured_media.source_url
      }
      pageName={data.allWordpressPage.edges[0].node.title}
    >
      <section className="py-4 w-full lg:w-8/12 mx-auto">
        <div
          dangerouslySetInnerHTML={{
            __html: data.allWordpressPage.edges[0].node.content,
          }}
        ></div>
      </section>
      <section className="py-4 w-11/12 lg:w-8/12 mx-auto">
        <Images imageOneSrc={acfData.image_one} imageTwoSrc={acfData.image_two}/>
      </section>
      <section className="py-4 w-full">
        {/* <BannerAlt bannerText={acfData.banner_text} bannerLink={acfData.banner_link} /> */}
        <div
      className={`${classes.liningBackground} w-full overflow-x-hidden mainCarousel`}
    >
      <div
        className={`py-6 w-full h-full bg-lightBlueAlpha ${classes.carouselBox}`}
      >
        <div className="w-full">
          <div className="w-full lg:w-10/12 mx-auto flex justify-center">
            <div className="w-7/12 z-20">
              <a
                className="h2 text-darkblue h1 leading-tight"
                href={acfData.banner_link}
                target="_blank"
              >
                {acfData.banner_text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
      </section>
      <CallToAction ctaCopy={acfData.call_to_action_content} />
      <Seo title={data.allWordpressPage.edges[0].node.title} description={data.allWordpressPage.edges[0].node.content} image={data.allWordpressPage.edges[0].node.featured_media.source_url} article={true} />
    </Layout>
  )
}

export default Construction
